<div class="about-us-container">
  <!-- Sidebar -->
  <aside class="sidebar">
    <nav>
      <ul class="sidebar-nav">
        <span *ngFor="let nav of navs">
          <li class="team-member"><a (click)="scrollTo(nav.value)">{{ nav.display }}</a></li>
          <span *ngIf="nav.order < navs.length - 1">
            <hr>
          </span>
        </span>
      </ul>
    </nav>
  </aside>
  <div class="page-container">
    <h3 class="about-us-banner">About Us</h3>
    <h4 class="about-us-sub-banner">About Us: Classmates and Partners</h4>
    <blockquote class="page-writing">
      After graduating together in 1976, William Fric and Howard Lowenstein committed themselves to
      serving the legal needs of their neighbours. Since 1982, Fric, Lowenstein & Co. LLP has offered
      prudent legal advice to citizens in Calgary and throughout Alberta. When you choose
      Fric, Lowenstein & Co. LLP, you put 5 lawyers and over 30 years of legal expertise to work for you.
      We offer a range of legal services for individuals as well as business owners in the province and our
      committed staff is ready to offer you first-rate legal representation. Call us today to speak to our team.  <br><br>
      <strong>Meet Our Staff</strong>  <br><br>
      Choosing a lawyer is a weighty decision. Learn more about the staff of Fric, Lowenstein & Co. LLP here.<br><br>
    </blockquote>
    <hr>
    <!--START OF TEAM-->
    <div class="profile-section" *ngFor="let profile of profiles">
      <div class="profile-content" id="{{profile.id}}">
        <img ngSrc="{{profile.image}}" width="160" height="160" alt="Member Thumbnail" class="profile-thumbnail" />
        <blockquote class="page-writing" >
          <span class="member-name"><strong>{{profile.name}}</strong></span> <br><br>
          <span *ngIf="profile.role" class="member-intro">
            <strong>{{profile.role}}</strong> <br><br>
            <span *ngIf="profile.workexperience">
              <div [innerHTML]="util.sanitizeHtml(profile.workexperience)"></div><br><br>
            </span>
          </span>
          <span *ngIf="!profile.role" class="member-intro">
            EDUCATION:<br>
            <span class="member-edu" *ngIf="profile.education">
              <div [innerHTML]="util.sanitizeHtml(profile.education)"></div><br>
            </span><div class="edu=spacer"></div>
            CALL TO THE BAR:<br>
            <span *ngIf="profile.calltobar">{{profile.calltobar}}<br><br></span>
            PRACTICE PROFILE:<br>
            <span *ngIf="profile.workexperience">
              <div [innerHTML]="util.sanitizeHtml(profile.workexperience)"></div><br><br>
            </span>
          </span>
          <div class="spacer"></div>
          <strong *ngIf="profile.achievements">PROFESSIONAL ASSOCIATION</strong> <br>
          <span class="bullet-point-list">
            <span *ngFor="let ach of profile.achievements" class="profile-item">
              <mat-icon class="bullet-point">chevron_right</mat-icon>
              <span [innerHTML]="util.sanitizeHtml(ach)"></span><br>
            </span>
          </span><br>
          <strong *ngIf="profile.community">COMMUNITY INVOLVEMENT</strong><br>
          <span class="bullet-point-list">
            <span *ngFor="let comm of profile.community" class="profile-item">
              <mat-icon class="bullet-point">chevron_right</mat-icon>
              <span [innerHTML]="util.sanitizeHtml(comm)" ></span><br>
            </span>
          </span>
        </blockquote>
      </div>
    </div>
    <!--END OF TEAM-->
  </div>
</div>

